module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-YQ5XJSEVGL","GTM-NVTSFGL"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Nunito","file":"https://fonts.googleapis.com/css2?family=Lato&family=Nunito:wght@200;300;400;700&display=swap"},{"name":"Lato","file":"https://fonts.googleapis.com/css2?family=Lato&family=Nunito:wght@200;300;400;700&display=swap"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
