exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-active-rehab-js": () => import("./../../../src/pages/active-rehab.js" /* webpackChunkName: "component---src-pages-active-rehab-js" */),
  "component---src-pages-acupuncture-js": () => import("./../../../src/pages/acupuncture.js" /* webpackChunkName: "component---src-pages-acupuncture-js" */),
  "component---src-pages-billing-js": () => import("./../../../src/pages/billing.js" /* webpackChunkName: "component---src-pages-billing-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-breast-chest-health-js": () => import("./../../../src/pages/breast-chest-health.js" /* webpackChunkName: "component---src-pages-breast-chest-health-js" */),
  "component---src-pages-chiropractic-js": () => import("./../../../src/pages/chiropractic.js" /* webpackChunkName: "component---src-pages-chiropractic-js" */),
  "component---src-pages-concussion-management-js": () => import("./../../../src/pages/concussion-management.js" /* webpackChunkName: "component---src-pages-concussion-management-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-counselling-js": () => import("./../../../src/pages/counselling.js" /* webpackChunkName: "component---src-pages-counselling-js" */),
  "component---src-pages-dizziness-vestibular-js": () => import("./../../../src/pages/dizziness-vestibular.js" /* webpackChunkName: "component---src-pages-dizziness-vestibular-js" */),
  "component---src-pages-firstvisit-js": () => import("./../../../src/pages/firstvisit.js" /* webpackChunkName: "component---src-pages-firstvisit-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intramuscular-stimulation-js": () => import("./../../../src/pages/intramuscular-stimulation.js" /* webpackChunkName: "component---src-pages-intramuscular-stimulation-js" */),
  "component---src-pages-knee-braces-js": () => import("./../../../src/pages/knee-braces.js" /* webpackChunkName: "component---src-pages-knee-braces-js" */),
  "component---src-pages-massage-therapy-js": () => import("./../../../src/pages/massage-therapy.js" /* webpackChunkName: "component---src-pages-massage-therapy-js" */),
  "component---src-pages-occupational-therapy-js": () => import("./../../../src/pages/occupational-therapy.js" /* webpackChunkName: "component---src-pages-occupational-therapy-js" */),
  "component---src-pages-orthotics-js": () => import("./../../../src/pages/orthotics.js" /* webpackChunkName: "component---src-pages-orthotics-js" */),
  "component---src-pages-physiotherapy-js": () => import("./../../../src/pages/physiotherapy.js" /* webpackChunkName: "component---src-pages-physiotherapy-js" */),
  "component---src-pages-practitioners-js": () => import("./../../../src/pages/practitioners.js" /* webpackChunkName: "component---src-pages-practitioners-js" */),
  "component---src-pages-shockwave-js": () => import("./../../../src/pages/shockwave.js" /* webpackChunkName: "component---src-pages-shockwave-js" */),
  "component---src-pages-temporomandibular-joint-disorder-js": () => import("./../../../src/pages/temporomandibular-joint-disorder.js" /* webpackChunkName: "component---src-pages-temporomandibular-joint-disorder-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

